.FileDropZone {
  min-height: auto !important;
  background: var(--bg-primary-color);
  border: 2px dashed var(--tecnea-blue) !important;
  color: var(--tecnea-blue);
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 50%;
}

.FileDropZone p {
  margin: 0;
  font-weight: bold;
  font-family: inherit;
  font-size: 1.6rem;
}

.FileDropZone svg {
  color: var(--tecnea-blue);
}

/* This hides everything related to this class 
.MuiTypography-subtitle1 {
  display: none;
}*/

.MuiDropzonePreviewList-root {
  max-width: 50%;
  margin: 0 !important;
}

.MuiDropzonePreviewList-imageContainer {
  padding: 0;
}

.MuiDropzonePreviewList-image {
  max-height: 100px;
  height: auto !important;
}

.drop-zone-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0;
}

@media screen and (max-width: 768px)  {
 .drop-zone-container {
    flex-direction: column;
  }

  .FileDropZone {
    max-width: 100%;
  }
}

