@import 'styles/_variables.css';
@import 'styles/_fonts.css';

*, ::after, ::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

@media (max-width: 768px) {
  html {
    font-size: 56.5%;
  }

  body {
    overflow-y: scroll;
  }
}

body {
  min-width: var(--wrapperWidthMin);
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  font-family: 'Roboto','OpenSans', Arial, Helvetica, sans-serif;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

a {
  color: var(--tecnea-blue);
  text-decoration: none;
  transition: all var(--transitionTime);
}

a:hover,
a.active {
  color: var(--tecnea-blue-dark);
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 100;
  margin: 0 0 .5rem 0;
}

h1 {
  font-size: 3.6rem;
}
h2 {
  font-size: 3.2rem;
}
h3 {
  font-size: 2.8rem;
}
h4 {
  font-size: 2.4rem;
}
h5 {
  font-size: 2.2rem;
}
h6 {
  font-size: 1.6rem;
}

small {
  font-size: 80%;
}

/* UTILS */
.mr1 {
  margin-right: 0.8rem;
}

.ml1 {
  margin-left: 0.8rem;
}

.mb1 {
  margin-bottom: 0.8rem;
}

.mt1 {
  margin-top: 0.8rem;
}

.selectField {
  border-radius: 0 0 8px 8px;
}

.step-completed {
  color: var(--success-color) !important;
}


/* IPHONE ZOOM INPUT FIX */
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea,
select:focus,
textarea:focus,
input:focus {
  font-size: 1.8rem !important;
}


/* MATERIAL UI */
body .MuiButton-root {
  min-height: 36px;
}

/* TOGGLE BUTTONS */
body .MuiToggleButton-root {
  min-width: 90px;
  color: var(--grey);
  border-color: var(--grey);
}

body .MuiToggleButton-root.Mui-selected {
  color: var(--white);
  background-color: var(--primary-color);
}

body .MuiToggleButton-root.Mui-selected:hover {
  background-color: var(--primary-color);
}

body .MuiToggleButton-root svg,
body .MuiDialogTitle-root svg {
  margin-right: 1rem;
}

/* MODALS */
body .MuiDialog-root {
  z-index: 1500 !important;
}

body .MuiDialog-paper form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

body .MuiDialogTitle-root {
  color: var(--white);
  background: var(--tecnea-blue-dark);
}

  body .MuiDialogTitle-root.errorModal {
    background: var(--danger-color);
  }

  body .MuiDialogTitle-root.warningModal {
    background: var(--warning-color);
  }

  body .MuiDialogTitle-root.confirmModal {
    background: var(--success-color);
  }

body .MuiDialogContent-root {
  padding: 12px 12px 0px;
}

/* TOOLTIPS */
body .MuiTooltip-tooltip {
  font-size: 1.2rem;
}

/* FORMS */
body .MuiInputBase-input.Mui-disabled {
  background-color: #f3f3f3;
  cursor: not-allowed;
}
body .MuiGrid-item {
  max-width: 100%;
}

/* STEPPER */
@media (max-width: 768px) {
  body .MuiStepper-root {
    padding: 24px 0;
  }
}

/* MOBILE SCROLLBARS */
@media (max-width: 768px) {
  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    border: 4px solid white;
  }
}