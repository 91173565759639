:root {
  /* COLORS */
  --tecnea-blue: #017db8;
  --tecnea-blue-dark: #02587E;
  --tecnea-blue-light: #009AE7;
  --tecnea-blue-lighter: #f2fafe;

  --primary-color: #008542;
  --primary-color-dark: #005c2e;
  --primary-color-light: #00af58;

  --disabled-color: #D9D9D9;
  --disabled-color-dark: #737373;

  --success-color: #38853c;
  --warning-color: #d8530d;
  --danger-color: #d23130;
  --delete-color: #dc004e;

  --grey-darker: #404040;
  --grey-dark: #737373;
  --grey: #a6a6a6;
  --grey-light: #d9d9d9;
  --grey-lighter: #ececec;

  --black: #000000;
  --white: #ffffff;

  /* RESPONSIVE WIDTHS */
  --wrapperWidthMax: 1200px;
  --wrapperWidthMin: 320px;

  --smallWrapperWidthMax: 1000px;
  --largeWrapperWidthMax: 1400px;

  --tabletWidth: 768px;

  /* MISC */
  --transitionTime: 0.3s;

  --header-height: 6rem;
  --header-nav-height: 30rem;
  --header-item-width: 220px;

  --zindexSmall: 10;
  --zindexMedium: 50;
  --zindexBig: 100;
}

@media (max-width: 768px) {
  :root {
    --header-item-width: 190px;
  }
}